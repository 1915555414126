header {
  height: 100vh;
  padding-top: 5rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
  padding-top: 5rem;
}

/* ============= CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ============= HEADER SOCIAL ============== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 1rem;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 4rem;
  background-color: var(--color-primary);
}

/* ============= ME ============== */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 20rem;
  height: 20rem;
  position: relative;
  left: calc(50% - 10rem); /* center image */
  margin-bottom: 4rem;
  border-radius: 12rem;
  backdrop-filter: blur(5px);
  overflow: hidden;
}

.scroll__down {
    position: absolute;
    right: -1rem;
    bottom: 5rem;
    transform: rotate(90deg);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }
  }

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
  }

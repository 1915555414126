.container.portfolio__container {
  padding-bottom: 3rem;
}

.portfolio__item {
  padding: 1.3rem;
  border-bottom: 0.2px solid var(--color-primary);
  border-radius: 2rem;
  backdrop-filter: blur(2px);
  transition: var(--transition);
}

.portfolio__items:hover {
  border: 1px solid var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 0rem;
}

.portfolio__item p {
  margin-bottom: 1rem;
  color: var(--color-light);
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: var(--color-primary);
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background-color: var(--color-primary);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
}
